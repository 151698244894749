/* styles.css */

/* Header styles */
.header {
  background-color: #001848; /* Updated background color */
  color: #fff;
  padding: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 115px; /* Adjusted height */
  padding: 0px 50px;
}
.stickyheader{
  
  position: sticky;
  top: 0;
  z-index: 100000000;
}

.logo img {
  max-width: 100%;
  height: auto;
  max-height: 115px; /* Adjust height as needed */
}

/* Navigation menu styles */
.nav-menu ul {
  list-style-type: none;
  padding: 0;
  display: flex;
}

.nav-menu ul li {
  margin-right: 20px;
}

.nav-menu ul li a {
  color: #fff;
  text-decoration: none;
}

.nav-menu ul li a:hover {
  color: #ff0; /* Change color on hover */
}
