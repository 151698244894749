
.navbar-icon img {
   max-height:50px;
}
.navbar-icon{
    position: relative;
  height: 40px; 
}
.menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff; /* Adjust as needed */
    border: 1px solid #ccc; /* Adjust as needed */
    border-radius: 4px; /* Adjust as needed */
    padding: 5px;
    z-index: 1;
  }
  
  .menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .menu ul li {
    padding: 5px 10px;
  }
  
  .menu ul li a {
    text-decoration: none;
    color: #333; /* Adjust as needed */
  }
  
  .menu ul li a:hover {
    color: #000; /* Adjust as needed */
    background-color: #f0f0f0; /* Adjust as needed */
  }