/* ProductPage.css */

.product-page {
    align-items: center;
     /* Light grey background */
    padding: 20px;
   
  }
  
  .product { 
    margin: auto;
    background-color: #fff; /* White background for each product */
    margin-bottom: 20px;
    padding: 20px;
    max-width: 80%;
    background-color: #f0f0f0;
   
    
  }
  .checkout-btn {
    width: 80%;
    display: block;  
    margin:  auto;
    margin-top: 10px;
    margin-bottom: 15px;
    text-align: center;
    padding: 10px;
    background-color: #001848;
    color: #fff;
    text-decoration: none;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  
  }
  .product-image {
    max-height: 100px;
    width: auto;
    margin-right : 15px;
    margin-top: 15px;
  }
  
  .reviews {
    display: flex;
    
  }
  .reviews p {
    padding-right: 10px;
  }
  @media screen and (max-width: 768px) {
    .divgo{
      margin :auto;
      margin-bottom: 10px;
      
    }
    .inlin{
      display: flex; 
  flex-direction: column; 
    }
  }
.inlin{
  display: flex;
}