/* CheckoutPage.css */

.checkout-page {
  max-width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.checkout-page h2 {
  margin-bottom: 20px;
  text-align: center;
}

.checkout-page form {
  display: grid;
  grid-gap: 10px;
}

.checkout-page form label {
  font-weight: bold;
  display: block;
}

.checkout-page form input[type="text"],
.checkout-page form input[type="email"],
.checkout-page form input[type="tel"],
.checkout-page form textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box; /* Ensure padding is included in width calculation */
}

.checkout-page form button {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 100px;
}

.checkout-page form button:hover {
  background-color: #0056b3;
}
