/* Footer.css */
.footer {
  background-color: #001848; /* Same blue color as header */
  color: #fff;
  text-align: center;
  padding: 10px 0; /* Adjust padding as needed */
  position: fixed;
  bottom: 0;
  width: 100%;
 
 
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensures the footer stays on top of other elements */
}

  
  /* Responsive styles */
  @media screen and (max-width: 768px) {
    .footer {
      font-size: 14px; /* Adjust font size for mobile */
    }
  }
  