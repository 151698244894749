.homepage{
  max-width: 80%;
  margin: auto;
}
/* Styles for Header with Video */
.headerh {
    position: relative;
 /* Adjust height as needed */
    overflow: hidden;
   
  }
  .ct{
    text-align: center;
  }
  .testimg{
    width: 80% ;
    height: auto;
  
  }
  
  .header-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Testimonial styles */
  .testimonials {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 0; /* Adjust padding as needed */
   
  }
  .cent{
    display: flex;
    justify-content: center; /* Horizontally center items */
    padding: 5bpx;
  }
  .react-player {
    position: relative;
    left: 0;
    top: 0;
    transform: none;
}
  .testimonial {
    flex: 1;
    margin: 15px 5px; /* Adjust margin as needed */
    background-color: #f0f0f0; /* Example background color */
    padding: 20px;
  }
  
  /* Call to Action styles */
  .cta {
    text-align: center;
    padding: 20px 0; /* Adjust padding as needed */
  }
  
  .cta h2 {
    margin-bottom: 10px;
  }
  .choose p{
    font-size: 18px;
  }
  .cta p {
    margin-bottom: 20px;
  }
  
  .butp{
    background-color: #001848;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 25px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: solid;
  }
  .buf{
    padding:  20px;
    padding-bottom: 100px;
  }

.container {
  display: flex;
  flex-direction: column; /* Default: vertical stacking for mobile */
  gap: 20px;
}

/* Styling for image */
.image {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.content-div {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
}

/* Responsive styles for larger screens */
@media (min-width: 768px) {
  .container {
    flex-direction: row; /* Horizontal layout on larger screens */
    gap: 30px; /* Space between the image and content */
  }

  .image-tab,
  .content-div {
    flex: 1; /* Take equal space side by side */
  }
  .content-div p{
    font-size: 20px;
  }

  .header {
    height: 300px; /* Adjust height for mobile */
  }

  .testimonials {
    flex-direction: row; /* Stack testimonials vertically on mobile */
  }

  .testimonial {
    margin: 10px 10px; /* Adjust margin for mobile */
  }
  .h1{
    font-size: 16px;
  }
}

