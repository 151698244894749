.booking-success {
    text-align: center;
    padding: 20px;
    margin-top:50px ;
  }
  
  .checkmark {
    font-size: 50px;
    color: green;
  }
  
  h2 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  
  button.pay-now {
    background-color: blue;
    color: white;
    border-radius: 5px;
  }
  
  button.pay-later {
    background-color: grey;
    color: white;
    border-radius: 5px;
  }
  