.admin-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .client-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 20px;
  }
  
  .client-item {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
  }
  
  .client-details {
    margin-top: 10px;
  }
  
  .client-details p {
    margin: 5px 0;
  }
  