/* AboutPage.css */

.about-container {
  margin:auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 80%;
    padding-bottom: 100px;
   
  }
  
  .about-content {
    text-align: center;
    max-width: 600px;
  }
  
  .about-content img {
    max-width: 100%;

    margin-bottom: 20px;
  }
  
  .about-content p {
    font-size: 18px;
    line-height: 1.6;
  }
  .about-content img {
    max-width: 150px; /* Set the maximum width to 350px */
    height: auto; /* Ensure the aspect ratio is maintained */
 
    margin-bottom: 20px;
  }
  